/* Add this to your index.css or create a new CSS file and import it */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --cream: #F7EFE5;
  --light-purple: #E2BFD9;
  --medium-purple: #C8A1E0;
  --dark-purple: #674188;
}

body {
  background-color: var(--cream);
  color: var(--dark-purple);
}

.App {
  text-align: center;
}

.button-3d {
  padding: 10px 20px;
  background: linear-gradient(to right, var(--medium-purple), var(--dark-purple));
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-3d:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.card-3d {
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.card-3d:hover {
  transform: translateY(-5px) rotate(2deg);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.background-3d {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-gradient {
  background: linear-gradient(to bottom right, var(--light-purple), var(--dark-purple));
}

.bg-3d {
  background: linear-gradient(45deg, var(--medium-purple), var(--dark-purple));
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
   0% { background-position: 0% 50%; }
   50% { background-position: 100% 50%; }
   100% { background-position: 0% 50%; }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
